@import "Stylesheets/nurikae/mixin/media_query";

.top-floating-call-to-action {
  display: grid;
  grid-template-columns: repeat(2, 400px);
  place-content: center;
  gap: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px 0 20px 0;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 30px 16px 20px 16px;
  }

  &__button-container {
    position: relative;

    &--pc {
      display: block;

      @include tablet {
        display: none;
      }
    }

    &--sp {
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  &__button-label {
    height: fit-content;
    width: fit-content;
    line-height: 1;
    font-size: 15px;
    font-weight: bold;
    color: #1c8a9e;
    background-color: #fff;
    border: 2px solid #1c8a9e;
    border-radius: 22px;
    padding: 6px 35px;
    position: absolute;
    top: -18px;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;

    &--secondary {
      color: #824c00;
      border: 2px solid #824c00;
    }

    @include tablet {
      font-size: 12px;
      padding: 5px 14px;
      top: -14px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    width: 395px;
    height: 65px;
    gap: 10px;
    background-color: #28a8c1;
    border-radius: 10px;
    padding: 6px 5px 5px 5px;
    color: #fff;
    align-items: center;
    box-shadow: 5px 5px 0 #d3f2f4;
    transition: filter 0.2s, transform 0.2s, box-shadow 0.2s;

    @include tablet {
      padding: 15px;
      gap: 8px;
      width: auto;
      height: auto;
    }

    &:hover {
      background-color: #80cbd9;
      opacity: 1;
      transform: translate(5px, 5px);
      box-shadow: none;
    }

    &--secondary {
      background-color: #e1981d;
      box-shadow: 5px 5px 0 #fef5e1;

      &:hover {
        background-color: #e6bb73;
      }
    }

    img {
      width: 35px;
      height: 35px;
      margin-left: -40px;

      @include tablet {
        width: 30px;
        height: 30px;
        margin-left: 0;
      }
    }
  }

  &__button-text {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    padding: 15px 0 10px 0;

    @include tablet {
      font-size: 18px;
      padding: 0;
    }
  }
}
