@import "Stylesheets/nurikae/mixin/media_query";

.breadcrumb {
  overflow-x: auto;
  white-space: nowrap;
  background-color: #f6f6f6;
  line-height: 1;
  padding: 8px 0;
  margin-top: 80px;

  &__list {
    width: 1000px;
    margin: 0 auto;
    padding: 0 16px;
    list-style: none;
    display: flex;

    @include tablet {
      width: 100%;
      margin: 0;
      padding: 0 16px;
    }
  }

  &__item {
    color: #192c55;
    font-size: 12px;

    &:last-child {
      padding-right: 16px;
    }
  }

  &__link {
    color: #192c55;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__separator {
    margin: 0 0.5rem;
  }
}
