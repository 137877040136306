.client-search-modal-for-top {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
    visibility: hidden;
    opacity: 0;

    &--active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__content {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    transition: visibility 0s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    &--active {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9fbfc;
    position: relative;
    padding: 10px;
    border-radius: 10px 10px 0 0;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    color: #808891;
  }

  &__close-button {
    position: absolute;
    right: 16px;
  }

  &__button {
    display: flex;
    gap: 9px;
    align-items: center;
    font: normal normal normal 16px/16px Hiragino Kaku Gothic Pro;
    letter-spacing: 0px;
    color: #000000;
    padding: 15px 45px;
    margin: 0 auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #00000029;
    border-radius: 25px;
  }
}
