.global-navigation {
  &__list {
    display: flex;
  }

  &__item {
    padding: 10px 6px 2px 6px;
  }

  &__text {
    font-size: 12px;
    color: #555;
    text-align: center;
  }

  &__unit {
    display: flex;
  }

  &__link {
    display: block;
    color: #333;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    padding: 7px 14px 14px;

    &:hover {
      background-color: #f1f7fb;
    }
  }

  &__separator {
    width: 1px;
    background-color: #cdd6dd;
    margin-bottom: 15px;
  }
}
