@mixin set-grid-container {
  width: 1000px;
  margin: 0 auto;
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(12, 55px);
  gap: 0 28px;

  @include tablet {
    width: 100%;
    margin: 0;
    padding: 0 16px;
    grid-template-columns: repeat(4, calc(((100vw - (16px * 2)) - (16px * 3)) / 4));
    gap: 0 16px;
  }
}

@mixin set-grid-column($pc-span: 12, $sp-span: 4) {
  grid-column: span $pc-span;

  @include tablet {
    grid-column: span $sp-span;
  }
}

.grid-container {
  @include set-grid-container;

  &__full-column {
    @include set-grid-column;
  }

  &__main-column {
    @include set-grid-column(7, 4);
  }

  &__sub-column {
    @include set-grid-column(5, 4);
  }
}
