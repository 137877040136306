@import "Stylesheets/nurikae/mixin/media_query";

.client-search {
  padding: 0 20px 20px 20px;

  @include tablet {
    padding: 0 20px 32px 20px;
  }

  &__lable {
    font-size: 13px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 23px;

    @include tablet {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  &__area-select {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  &__select {
    width: 100%;
    font-size: 15px;
    line-height: 1;
    padding: 14px 20px;
    border-radius: 6px;
    background: #fff url('./images/client_search_down_arrow.svg') no-repeat right 11px center;
    border: 1px solid #cdd6dd;
    appearance: none;
  }

  &__select:invalid {
    color: #b9b9b9;
    background-color: #f9f9f9;
  }

  &__part-type-radio-group {
    display: flex;

    label {
      font-size: 13px;
      line-height: 1;
      width: 100%;
      padding: 12px 0;
      text-align: center;
      border: 1px solid #cdd6dd;
      background-color: #fff;
      color: #333;

      @include tablet {
        font-size: 15px;
        padding: 14px 0;
      }
    }

    label:first-of-type {
      border-radius: 4px 0px 0px 4px;
    }

    label:nth-of-type(2) {
      border-radius: 0px 4px 4px 0px;
    }
  }

  &__radio {
    display: none;

    &:checked + label {
      background-color: #f1f7fb;
      color: #1c8a9e;
      font-weight: bold;
      border: 2px solid #1c8a9e;
    }
  }

  &__button-wrap {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  &__button {
    display: flex;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    color: #1c8a9e;
    border-radius: 6px;
    background: #fff;
    border: 1px solid #1c8a9e;
    padding: 9px 17px;

    @include tablet {
      font-size: 16px;
      padding: 15px 21px;
    }

    img {
      display: inline;
      margin-right: 5px;
    }
  }
}
