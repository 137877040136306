.floating {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;

  &--show {
    display: block;
  }
}
