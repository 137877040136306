@import "Stylesheets/nurikae/mixin/media_query";
@import "Stylesheets/nurikae/util/grid";

.top-call-to-action {
  background-color: #fff;
  position: relative;
  padding-bottom: 60px;

  @include tablet {
    padding: 24px 0 43px;
  }

  @include smartphone {
    padding: 4px 0 43px;
  }

  &__background-ellipse {
    position: absolute;
    bottom: 100%;
    width: 100%;

    &--pc {
      display: block;

      @include tablet {
        display: none;
      }
    }

    &--sp {
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  &__check {
    width: 37px;
    height: 36.91px;
    top: -2.02px;

    @include tablet {
      width: 24.47px;
      height: 22.95px;
    }
  }

  &__grid-container {
    @include set-grid-container;
  }

  &__full-column-container {
    @include set-grid-column;
  }

  &__heading {
    margin-top: 20px;
    display: flex;
    align-items: center;

    @include tablet {
      margin-top: 5px;
      justify-content: center;
      align-items: nomal;
    }
  }

  &__heading-text {
    font-family: 'Hiragino Kaku Gothic Std';
    color: #E1981D;
    font-size: 26px;
    font-weight: 800;
    display: inline;
    font-style: normal;
    line-height: 34px;

    @include tablet {
      font-size: 17.4775px;
    }

    &--blue {
      color: #1C8A9E;
    }
  }

  &__links-container {
    margin-top: 20px;
    display: grid;

    @include tablet {
      margin-top: 10px;
    }

    &--pc {
      display: grid;

      @include tablet {
        display: none;
      }
    }

    &--sp {
      display: none;

      @include tablet {
        display: grid;
      }
    }

    &--sp-columns {
      @include tablet {
        row-gap: 12px;
      }
    }

    &--pc-columns {
      grid-template-columns: repeat(6, 1fr);
      gap: 23px;
    }
  }

  &__button {
    border-radius: 12px;
    border: 2px solid #1c8a9e;
    color: #1c8a9e;
    background-color: rgb(250, 250, 250);
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 15px 0;
    width: 100%;

    @include tablet {
      display: grid;
      grid-template-columns: 50px 1fr;
      gap: 7px;
      padding: 9px 0 9px 5px;
      text-align: left;
      align-items: center;
    }

    &:nth-of-type(1) {
      grid-column: 2 / span 2;

      @include tablet {
        grid-column: auto;
      }
    }

    &:nth-of-type(2) {
      grid-column: 4 / span 2;

      @include tablet {
        grid-column: auto;
      }
    }

    &:nth-of-type(3) {
      grid-column: 1 / span 2;

      @include tablet {
        grid-column: auto;
      }
    }

    &:nth-of-type(4) {
      grid-column: 3 / span 2;

      @include tablet {
        grid-column: auto;
      }
    }

    &:last-of-type {
      grid-column: 5 / span 2;

      @include tablet {
        grid-column: auto;
      }
    }

    &:hover {
      opacity: 1;
      color: #f8bf52;
      border: 2px solid #f8bf52;

      &::after {
        background: #f8bf52;
      }

      img {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background: #1c8a9e;
      height: 40px;
      width: 40px;
      clip-path: polygon(100% 0, 100% 100%, 0 100%);
      bottom: -3px;
      right: -3px;
    }
  }

  &__button-icon {
    margin: auto;
    width: 80px;

    &--sp-columns {
      @include tablet {
        width: 34px;
        height: 34px;
      }
    }
  }

  &__button-text-container {
    margin-top: 10px;
    line-height: 1;

    @include tablet {
      margin-top: 0;
    }
  }

  &__button-text {
    font-size: 24px;
    font-weight: bold;

    @include tablet {
      font-size: 18px;
    }

    &--sub {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 8px;

      @include tablet {
        font-size: 12px;
        margin-bottom: 6px;
      }
    }

    &--sp-columns {
      @include tablet {
        font-size: 16px;
      }
    }

    &--pc-columns {
      font-size: 26px;
    }
  }

  &__circle-arrow-icon {
    position: absolute;
    bottom: 4px;
    right: 3px;
    width: 12px;
    height: 12px;
    z-index: 1;
  }
}
