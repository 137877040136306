.dropdown-links-menu {
  &__item {
    border-bottom: #cdd6dd 1px solid;

    &:last-child {
      border-bottom: none;
    }
  }

  &__link {
    display: block;
    padding: 18px 28px;
    white-space: nowrap;
    color: #333;
    font-size: 14px;
    position: relative;

    &:hover {
      color: #1c8a9e;
      font-weight: bold;

      &::after {
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 7px;
        height: 7px;
        border-top: 2px solid #1c8a9e;
        border-right: 2px solid #1c8a9e;
        transform: translate(7px) rotate(45deg);
      }
    }
  }
}
