@import '../common/default_variable';

$tablet-width-number: $default-nurikae-max-width-number - 1;
$smartphone-width-number: 576;
$smartphone-min-width-number: 320;

$tablet-break-point-width: #{$tablet-width-number}px;
$smartphone-break-point-width: #{$smartphone-width-number}px;

$tablet-clamp-basis: $smartphone-width-number + 1;
$smartphone-clamp-basis: $smartphone-min-width-number;

@mixin tablet {
  @media (max-width: #{$tablet-break-point-width}) {
    @content;
  }
}

@mixin smartphone {
  @media (max-width: #{$smartphone-break-point-width}) {
    @content;
  }
}

@mixin custom-media-query($width) {
  @media (max-width: #{$width}) {
    @content;
  }
}
