.header {
  padding-top: 11px;
  border-bottom: #e0eaef 2px solid;

  &-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1000px;
    margin: 0 auto;
    padding: 0 16px;
  }

  &-title {
    &__explanation {
      line-height: 1;
      font-size: 10px;
      color: #98a6b5;
      margin-bottom: 11px;
      white-space: nowrap;
    }

    &__logo {
      width: 156px;
      height: 32px;
      margin-bottom: 10px;
    }
  }
}
