@import "Stylesheets/nurikae/mixin/media_query";
@import "Stylesheets/nurikae/util/grid";

.top-columns {
  padding-bottom: 40px;
  @include smartphone {
    padding-bottom: 0px;
  }

  &__grid-container {
    @include smartphone {
      display: none;
    }
    @include set-grid-container;
  }

  &__list-container {
    display: none;
    @include smartphone {
      display: block;
    }
  }

  &__full-column-container {
    position: relative;
    @include set-grid-column;
  }

  &__grid-container-sp {
    display: none;
    @include smartphone {
      display: block;
    }

    @include set-grid-container;
    background-color: #f1f7fb;
  }

  &__full-column-container-sp {
    position: relative;
    @include set-grid-column;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 28px;
    margin-top: 33px;

    @include smartphone {
      grid-template-columns: repeat(1, 1fr);
      gap: unset;
      margin-top: 22px;
    }
  }

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px 0px 30px;
    background-color: #28a8c1;
    color: #fff;
    font-weight: bold;
    font-size: 26px;
  }

  &__content {
    @include smartphone {
      background-color: #fff;
    }
  }

  &__label {
    text-align: center;
    padding-top: 15px;
    font-weight: bold;
    font-size: 20px;

    @include smartphone {
      font-size: 18px;
    }
  }

  &__item {
    @include smartphone {
      padding: 20px;
    }
  }

  &__item.bg-base-color {
    background-color: #edf9fc;
  }

  &__title {
    text-align: center;
    margin: 10px 0 20px;
    font-weight: bold;
    color: #006881;
    padding-right: 10px;

    @include smartphone {
      display: flex;
      align-items: center;
      font-size: 19px;
    }
  }

  &__image {
    @include smartphone {
      margin-right: 10px;
    }
  }

  &__step-image {
    margin-right: 10px;
  }

  &__links {
    display: flex;
    margin-top: 20px;
  }

  &__link {
    display: block;
    color:#1c8a9e;
    text-decoration: underline;
    font-size: 14px;
    margin-bottom: 5px;

    @include smartphone {
      margin-bottom: 8px;
    }
  }

  &__arrow-right {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 2px;
    color: #006881;
  }

  &__five-steps {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    top: -16%;

    @include smartphone {
      top: -7%;
    }
  }
}
