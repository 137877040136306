@import 'Stylesheets/nurikae/common/default_variable';
@import "Stylesheets/nurikae/mixin/media_query";

.scroll-to-top-button {
  width: fit-content;
  margin-bottom: -50px;
  position: sticky;
  bottom: 20px;
  left: calc(100% - ((100vw - #{$default-nurikae-contents-max-width}) / 2) - 50px);
  display: none;

  @include tablet {
    left: calc(100% - 50px - 16px);
  }

  &__link {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(40, 168, 193, 0.55);
    border-radius: 50%;
  }

  &__link-icon {
    margin-bottom: 2px;
  }
}
