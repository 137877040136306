@import "Stylesheets/nurikae/mixin/media_query";

.top-supervisor {
  background-color: #e0eaef;
  border-radius: 12px;
  padding: 70px 0 60px;

  @include tablet {
    padding: 51px 0 27px;
  }

  &__text-container {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 0 220px;

    @include tablet {
      padding: 0 116px;
    }

    @include smartphone {
      font-size: 14px;
      padding: 0 29px;
      line-height: 2;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 28px;
    text-align: center;
    margin-top: 45px;

    @include smartphone {
      grid-template-columns: repeat(2, 1fr);
      gap: 5px 15px;
    }
  }

  &__thumbnail {
    width: 98px;
    height: 98px;
    margin: auto;
  }

  &__name {
    display: block;
    font-size: 14px;
    line-height: 1;
    margin-top: 15px;

    &:hover {
      text-decoration: underline;
      opacity: 1;
    }

    @include tablet {
      font-size: 12px;
      margin-top: 16px;
    }
  }

  &__position {
    font-size: 10px;
    color: #98a6b5;
    margin-top: 9px;

    @include tablet {
      margin-top: 12px;
    }
  }
}
