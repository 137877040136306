@import "Stylesheets/nurikae/mixin/media_query";
@import "Stylesheets/nurikae/mixin/get_clamp_value";

.review-card {
  display: block;
  container-type: inline-size;

  &__inner {
    height: 100%;
    padding: 25px 25px 20px 25px;
    background-color: #fff;
    border: 1px solid #cdd6dd;
    border-radius: 4px;

    @include tablet {
      padding: get-clamp-value(18px, 25px, $tablet-clamp-basis) get-clamp-value(20px, 25px, $tablet-clamp-basis) 20px get-clamp-value(20px, 25px, $tablet-clamp-basis);
    }

    @container (max-width: #{$smartphone-break-point-width}) {
      padding: 18px get-clamp-value(14px, 20px) 20px get-clamp-value(14px, 20px);
    }
  }

  &__contents {
    display: grid;
    gap: 12px 25px;
    grid-template-columns: 80px 1fr;

    @container (max-width: #{$smartphone-break-point-width}) {
      gap: 10px get-clamp-value(10px, 14px);
      grid-template-columns: 60px 1fr;
      grid-template-rows: auto 1fr auto auto;
    }
  }

  &__avatar {
    width: 80px;
    grid-row: 1 / 5;
    grid-column: 1 / 2;

    @include tablet {
      width: get-clamp-value(60px, 80px, $tablet-clamp-basis);
    }

    @container (max-width: #{$smartphone-break-point-width}) {
      width: get-clamp-value(50px, 60px);
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
  }

  &__attributes {
    grid-column: 2 / 3;

    @container (max-width: #{$smartphone-break-point-width}) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }

  &__attributes-rating {
    margin-bottom: 10px;

    .star-rating__icon {
      width: 20px;

      @include tablet {
        width: get-clamp-value(16px, 20px, $tablet-clamp-basis);
      }

      @container (max-width: #{$smartphone-break-point-width}) {
        width: 16px;
      }
    }

    .star-rating__number {
      font-size: 20px;

      @include tablet {
        font-size: get-clamp-value(18px, 20px, $tablet-clamp-basis);
      }

      @container (max-width: #{$smartphone-break-point-width}) {
        font-size: get-clamp-value(16px, 18px);
      }
    }
  }

  &__attributes-text {
    color: #98a6b5;
    font-size: 11px;
  }

  &__client-name {
    grid-row: 2 / 3;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-clamp: 2;

    @container (max-width: #{$smartphone-break-point-width}) {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
    }

    @include tablet {
      font-size: get-clamp-value(16px, 18px, $tablet-clamp-basis);
    }
  }

  &__comment {
    color: #393939;
    font-size: 16px;

    @include tablet {
      font-size: get-clamp-value(14px, 18px, $tablet-clamp-basis);
      grid-column: 2 / 3;
    }

    @container (max-width: #{$smartphone-break-point-width}) {
      font-size: get-clamp-value(13px, 14px);
      grid-row: 3 / 4;
      grid-column: 1 / 3;
    }
  }

  &__posted-date {
    color: #98a6b5;
    font-size: 12px;
    grid-column: 2 / 3;

    @container (max-width: #{$smartphone-break-point-width}) {
      grid-row: 4 / 5;
      grid-column: 1 / 3;
    }
  }

  &__helpful {
    color: #8bbacb;
    font-size: 11px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0 5px;
    grid-column: 2 / 3;

    @container (max-width: #{$smartphone-break-point-width}) {
      grid-row: 5 / 5;
      grid-column: 1 / 3;
    }

    &--done {
      color: #cbd4de;

      .review-card__helpful-icon--good {
        display: none;
      }

      .review-card__helpful-icon--check {
        display: block;
      }
    }
  }

  &__helpful-icon {
    &--check {
      display: none;
    }
  }

  &__helpful-text {
    line-height: 1;
  }

  &__helpful-text-number {
    font-weight: bold;
    padding-left: 5px;
  }
}
