.dropdown-button {
  position: relative;
  z-index: 2;

  &__trigger {
    display: flex;
    color: #333;
    font-weight: bold;
    line-height: 1;
    padding: 7px 13px 14px;
    align-items: center;

    &:hover {
      background-color: #f1f7fb;
    }

    &--open {
      background-color: #f1f7fb;
    }
  }

  &__text {
    margin-right: 5px;
    font-size: 14px;
    // 旧デザインページで使用する際、旧デザインの _reset.scss で変更されてしまうため font-family を指定する
    // app/packs/stylesheets/site/base/common/_reset.scss
    font-family: "Hiragino Kaku Gothic ProN", "Helvetica Neue", "arial", "Hiragino Sans", "BIZ UDPGothic", "meiryo", sans-serif;
  }

  &__arrow {
    width: 9px;
    height: 9px;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    visibility: hidden;
    opacity: 0;

    &--show {
      visibility: visible;
      opacity: 1;
    }
  }
}
