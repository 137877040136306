@import "Stylesheets/nurikae/mixin/media_query";
@import "Stylesheets/nurikae/util/grid";

.top-main-visual {
  &__container {
    background-color: #28a8c1;
    position: relative;

    @include tablet {
      background-image: url('images/top_main_visual_background_tablet.png');
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
    }

    @include smartphone {
      background-image: url('images/top_main_visual_background_sp.png');
    }
  }

  &__sp {
    display: none;

    @include smartphone {
      display: block;
    }
  }

  &__pc {
    display: block;

    @include tablet {
      display: none;
    }
  }

  &__tablet {
    display: none;

    @include tablet {
      display: block;
    }

    @include smartphone {
      display: none;
    }
  }

  &__join-link {
    font-weight: bold;
    font-size: 13px;
    position: absolute;
    top: 20px;
    right: 0;
    background-color: #effcfe;
    padding: 10px 18px;
    color: #1c8a9e;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

    &:hover {
      background-color: #fff;
      opacity: 1;
    }

    &::before {
      content: url('images/top_main_visual_flag.svg');
      position: relative;
      top: 4px;
      margin-right: 7px;
    }

    &::after {
      content: url('images/top_main_visual_arrow_right.svg');
      margin-left: 10px;
    }
  }

  &__grid-container {
    @include set-grid-container;
  }

  &__full-column-container {
    @include set-grid-column;
    background-image: url('images/top_main_visual_background.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    padding-bottom: 70px;

    @include tablet {
      background-image: none;
      padding-bottom: 80px;
    }

    @include smartphone {
      padding-bottom: 60px;
    }
  }

  &__count-infomations {
    position: absolute;
    display: flex;
    gap: 10px;
    top: 50px;
    margin-left: 250px;

    @include tablet {
      margin-left: 215px;
    }

    @include smartphone {
      display: block;
      top: 45px;
      margin-left: 180px;
    }

    .top-main-visual__count-image:nth-child(1) {
      @include smartphone {
        margin-left: 23px;
      }
    }
  }

  &__count-image {
    width: 70px;
    height: 70px;

    @include smartphone {
      width: 55px;
      height: 55px;
    }
  }

  &__sub-title-text {
    line-height: 1;
    font-weight: bold;
    font-size: 20px;
    color: #333;
    margin-top: 20px;
    margin-left: 13px;

    @include tablet {
      font-size: 18px;
      margin-top: 15px;
    }

    @include smartphone {
      font-size: 14px;
      margin-top: 15px;
    }
  }

  &__sub-text {
    line-height: 1;
    font-size: 10px;
    color: #333;
    margin-top: 15px;
    margin-bottom: 14px;

    @include smartphone {
      font-size: 8px;
      margin-top: 10px;
    }
  }

  &__title {
    margin-top: 20px;

    @include smartphone {
      margin-top: 0;
    }

    &--dot-container {
      width: 208px;
      display: flex;
      justify-content: space-around;
      margin: 6px 0 4px 0;

      @include smartphone {
        width: 144px;
        margin: 4px 0 4px 0;
      }
    }

    &--dot {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: #fff387;

      @include smartphone {
        width: 11px;
        height: 11px;
      }
    }
  }

  &__title-text {
    line-height: 1;
    display: inline-block;
    font-size: 36px;
    color: #f1f7fb;

    @include smartphone {
      font-size: 30px;
    }

    &--first {
      font-size: 27px;
      margin-left: 8px;

      @include tablet {
        font-size: 24px;
      }

      @include smartphone {
        font-size: 20px;
      }
    }

    &--last {
      @include smartphone {
        margin-top: 5px;
      }
    }

    &--emphasize {
      color: #fff387;
      font-size: 52px;

      @include smartphone {
        font-size: 36px;
      }
    }
  }

  &__search-modal {
    position: relative;

    @include smartphone {
      z-index: 90;
    }
  }
}
