@import "Stylesheets/nurikae/mixin/media_query";

.top-reform-example-card {
  display: block;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  position: relative;

  &:hover {
    opacity: 1;

    .top-reform-example-card__description {
      font-weight: bold;
    }

    img {
      transform: scale(1.2,1.2);
    }
  }

  img {
    transition: 0.4s all;
  }

  &__address-label {
    position: absolute;
    background-color: #00b2d3;
    color: #fafafa;
    font-size: 12px;
    line-height: 1;
    padding: 7px;
    z-index: 1;
  }

  &__thumbnail {
    height: 166px;
    overflow: hidden;

    img {
      width: 100%;
      height: 166px;
    }

    @include tablet {
      height: auto;
      min-height: 117px;

      img {
        height: auto;
        min-height: 117px;
        max-height: 262px;
      }
    }

    &--no-image {
      display: grid;
      place-content: center;
      background-color: #f5f5f5;

      img {
        height: auto;
        min-height: auto;
      }
    }
  }

  &__text-container {
    padding: 20px 20px 28px 20px;

    @include tablet {
      padding: 12px 12px 28px 12px;
    }
  }

  &__description {
    font-size: 13px;
    color: #555;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @include tablet {
      font-size: 12px;
    }
  }

  &__article_info {
    font-size: 12px;
    color: #98a6b5;
    margin-top: 15px;

    @include tablet {
      font-size: 10px;
      margin-top: 12px;
    }
  }
}
