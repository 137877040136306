@import 'Stylesheets/nurikae/common/reset';
@import "Stylesheets/nurikae/mixin/media_query";
@import "Stylesheets/nurikae/util/display_by_device";
@import "Stylesheets/nurikae/util/heading";
@import "Stylesheets/nurikae/util/grid";

body {
  background: #fff;
  color: #393939;
  font-size: 16px;
  line-height: 1.6;
  font-family: "Hiragino Kaku Gothic ProN", "Helvetica Neue", "arial", "Hiragino Sans", "BIZ UDPGothic", "meiryo", sans-serif;
  overflow-wrap: anywhere;
  word-break: normal;
  line-break: strict;

  @include tablet {
    font-size: 14px;
  }
}

a {
  text-decoration: none;

  &:hover {
    opacity: 0.8;

    img {
      opacity: 0.8;
    }
  }
}
