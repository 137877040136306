.dropdown-area-menu {
  display: flex;

  &--active {
    background-color: #f1f7fb;
    border-radius: 8px 0 0 8px;
    border-right: #cdd6dd 1px solid;
  }

  &__region-item {
    border-bottom: #cdd6dd 1px solid;
    padding: 20px 33px;
    height: 54px;
    line-height: 1;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;

    &:last-child {
      border-bottom: none;
    }

    &--active {
      color: #fff;
      background-color: #28a8c1;
      position: relative;
      margin-right: -1px;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
      }

      &::after {
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -10px;
        width: 0;
        height: 0;
        border-left: 15px solid #28a8c1;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        z-index: 1;
      }
    }
  }

  &__prefecture-container {
    background-color: #fff;
    border-radius: 0 8px 8px 0;
    position: absolute;
    left: 100%;
  }

  &__prefecture-group {
    display: none;

    &--active {
      display: block;
    }
  }

  &__prefecture-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 224px;
    height: 324px;
  }

  &__prefecture-item {
    width: 112px;
    text-align: center;
  }

  &__link {
    display: block;
    font-size: 14px;
    color: #333;
    padding: 20px;
    line-height: 1;
    position: relative;

    &:hover {
      color: #1c8a9e;
      font-weight: bold;

      &::after {
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 7px;
        height: 7px;
        border-top: 2px solid #1c8a9e;
        border-right: 2px solid #1c8a9e;
        transform: translate(7px) rotate(45deg);
      }
    }
  }
}
