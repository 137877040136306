.heading-type-1 {
  font-size: 32px;
  margin-top: 50px;

  @include tablet {
    font-size: 18px;
    margin-top: 15px;
  }
}

.heading-type-2 {
  text-wrap: wrap;
  font-size: 22px;
  line-height: 1.2;
  margin-top: 80px;
  color: #006881;
  border-bottom: #006881 solid 2px;

  @include tablet {
    font-size: 18px;
    margin-top: 55px;
  }
}

.heading-type-3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 45px;
  margin-bottom: 16px;

  @include tablet {
    font-size: 16px;
    margin-top: 40px;
  }
}
