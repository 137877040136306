@import "Stylesheets/nurikae/mixin/media_query";

.top-main-visual-client-search {
  position: absolute;
  margin: 0 calc(50% - 50vw);
  top: -70px;
  right: 0;
  left: 0;
  z-index: 1;

  @include tablet {
    top: -80px;
  }

  @include smartphone {
    top: -60px;
  }

  &__pc-content {
    display: block;
    position: relative;

    @include smartphone {
      display: none;
    }
  }

  &__container {
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #00000029;
    border-radius: 10px;
    width: fit-content;
    margin: 0 auto;
  }

  &__title {
    display: flex;
    gap: 18px;
    align-items: center;
    padding-left: 27px;

    @include tablet {
      padding-left: 15px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__title-text {
    font: normal normal normal 14px/30px Hiragino Kaku Gothic Pro;
    letter-spacing: 0px;
    color: #000000;

    @include tablet {
      width: 72px;
      font: normal normal normal 12px/16px Hiragino Kaku Gothic Pro;
    }
  }

  &__partition {
    transform: matrix(0, 1, -1, 0, 0, 0);
    text-align: left;
    font: normal normal normal 14px/30px Hiragino Kaku Gothic Pro;
    letter-spacing: 0px;
    color: #9D9D9D;

    @include tablet {
      height: 45px;
      text-align: left;
      margin: 0 -10px 0 -15px;
    }
  }

  &__region-list {
    display: flex;
    margin-right: 20px;
  }

  &__region-item {
    font: normal normal normal 15px/30px Hiragino Kaku Gothic Pro;
    letter-spacing: 0px;
    color: #000000;
    padding: 10px 15px;

    &--active {
      color: #1698B1;
    }
  }

  &__prefecture-list {
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    width: fit-content;
    position: absolute;
    border-radius: 10px;
    top: 60px;
    padding: 0 15px;
  }

  &__prefecture-item {
    font: normal normal normal 15px/30px Hiragino Kaku Gothic Pro;
    letter-spacing: 0px;
    color: #000000;
    padding: 10px;

    &--active {
      color: #1698B1;
    }
  }

  &__prefecture-group {
    display: none;

    &--active {
      display: block;
    }
  }

  &__bubble {
    position: absolute;
    width: 200px;
    height: 20px;
  }

  &__bubble-arrow {
    width: 100%;
    height: 100%;
    background: #ffffff;
    clip-path: polygon(50% 0%, 45% 100%, 55% 100%);
  }

  &__sp-content {
    display: none;

    @include smartphone {
      display: block;
    }
  }
}

