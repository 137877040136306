@import "Stylesheets/nurikae/mixin/media_query";

.top-search-client {
  &__heading-container {
    display: flex;
    align-items: center;
  }

  &__heading-image {
    width: 42px;
    height: 42px;

    @include tablet {
      width: 26px;
      height: 26px;
    }
  }

  &__heading-text {
    font-size: 30px;
    margin-left: 10px;

    @include tablet {
      font-size: 20px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 249px 719px;
    margin-top: 29px;
    height: 405px;
    background: #fff;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.06));
    border-radius: 12px;
    position: relative;

    @include tablet {
      grid-template-columns: 100%;
      grid-template-rows: 66px;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      margin-top: 20px;
      height: 100%;
    }
  }

  &__nurikaeru {
    position: absolute;
    right: 2px;
    bottom: -25px;

    @include tablet {
      display: none;
    }
  }

  &__select-condition {
    display: grid;
    grid-template-rows: 135px 135px 135px;

    @include tablet {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 100%;
    }
  }

  &__condition-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;
    padding: 24px;
    background-color: #f8f8f8;
    color: #98a6b5;

    @include tablet {
      padding: 24px;
    }

    &:first-child {
      border-top-left-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
    }

    &:hover {
      color: #28a8c1;

      .top-search-client__button-text--big {
        border-bottom: 2px solid #fff387;
      }
    }

    svg {
      width: 72px;
      height: 72px;
      color: #98a6b5;

      @include tablet {
        width: 40px;
        height: 40px;
      }
    }

    &--selected {
      color: #fff;
      background-color: #28a8c1;
      position: relative;
      margin-right: -1px;

      &::after {
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -10px;
        width: 0;
        height: 0;
        border-left: 15px solid #28a8c1;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        z-index: 1;

        @include tablet {
          top: auto;
          bottom: -10px;
          right: 50%;
          transform: translateX(25px);
          border-bottom: none;
          border-top: 15px solid #28a8c1;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
        }
      }

      // ホバー時のスタイルを上書きして無効化
      &:hover {
        color: #fff;

        .top-search-client__button-text--big {
          border-bottom: 2px solid #fff387;
        }
      }

      .top-search-client__button-text--big {
        border-bottom: 2px solid #fff387;
      }

      svg {
        color: white;
      }
    }

    &--middle {
      border-top: 2px solid #e0eaef;
      border-bottom: 2px solid #e0eaef;

      @include tablet {
        border-top: none;
        border-bottom: none;
        border-left: 2px solid #e0eaef;
      }
    }

    &--pc {
      @include tablet {
        display: none;
      }
    }
  }

  &__button-text {
    font-size: 16px;
    line-height: 26px;

    @include tablet {
      font-size: 12px;
      line-height: 20px;
    }

    &--big {
      display: inline-block;
      border-bottom: 2px solid transparent; // ホバー時に下線を表示した際に高さが変わらないようにする
      font-size: 20px;

      @include tablet {
        font-size: 16px;
      }
    }
  }

  &__link-container {
    padding: 25px 37px 20px 37px;
    display: grid;
    grid-template-rows: 26px;

    &--hide {
      display: none;
    }

    @include tablet {
      padding: 22px 16px;
    }
  }

  &__icon-text {
    display: flex;
    gap: 7px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;

    @include tablet {
      font-size: 14px;
      gap: 4px;
    }

    img {
      @include tablet {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__select-prefecture {
    padding-top: 15px;
    display: grid;
    grid-template-columns: repeat(64, 10px);
    grid-template-rows: repeat(2, 140px);
    row-gap: 25px;
    background-image: url("images/top_search_client_map.png");
    background-size: contain;
    background-position: right 115px center;
    background-repeat: no-repeat;
    align-items: end;

    @include tablet {
      display: none;
    }
  }

  &__region-wrapper {
    height: fit-content;
    width: fit-content;

    &:nth-child(1) {
      grid-column: 1 / 21;
    }

    &:nth-child(2) {
      grid-column: 24 / 39;
    }

    &:nth-child(3) {
      grid-column: 50 / 65;
      margin-bottom: 30px;
    }

    &:nth-child(4) {
      grid-column: 1 / 16;
    }

    &:nth-child(5) {
      grid-column: 19 / 34;
    }

    &:nth-child(6) {
      grid-column: 41 / 61;
    }
  }

  &__region-box {
    background: rgba(237, 249, 252, 0.5);
    padding: 10px;
    border-radius: 4px 4px 0 0;

    &:first-child {
      border-radius: 4px;
    }
  }

  &__region-name {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #006881;
  }

  &__prefecture-links {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(3, 44px);
    justify-content: center;
    row-gap: 11px;

    &--four-columns {
      grid-template-columns: repeat(4, 44px);

    }
  }

  &__prefecture-link {
    font-size: 13px;
    font-weight: bold;
    color: #333;
    text-align: center;
    line-height: 1;

    &:hover {
      color: #28a8c1;
      text-decoration: underline;
    }

    border-left: 1px solid #cdd6dd;

    &--three-columns {
      &:nth-child(3n+1) {
        border-left: none;
      }
    }

    &--four-columns {
      &:nth-child(4n+1) {
        border-left: none;
      }
    }
  }

  &__select-part-type {
    display: flex;
    justify-content: space-around;
    margin-top: 43px;

    @include tablet {
      gap: 16px;
      margin-top: 22px;
    }
  }

  &__part-type-link {
    width: 280px;
    height: 230px;
    border-radius: 12px;
    background: #f1f7fb;
    text-align: center;
    padding: 25px 0 20px;
    color: #1c8a9e;

    @include tablet {
      width: 100%;
      height: 100%;
      background: #fafafa;
      border: 2px solid #1c8a9e;
      padding: 22px 0 13px;
    }
  }

  &__part-type-image {
    margin: auto;

    @include tablet {
      width: 60px;
      height: 60px;
    }
  }

  &__part-type-construction-text {
    font-size: 14px;
    line-height: 1;
    margin-top: 9px;

    @include tablet {
      margin-top: 17px;
      font-size: 12px;
      padding: 0 22px;
    }
  }

  &__part-type-name-text {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
    margin-top: 7px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      font-size: 18px;
    }

    img {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 20px;

      @include tablet {
        width: 12px;
        height: 12px;
        right: 16px;
      }
    }
  }

  &__sp-content {
    display: none;

    @include tablet {
      display: block;
      margin-top: 43px;
    }
  }

  &__select-part-type-heading {
    @include tablet {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__region-buttons,
  &__prefecture-buttons {
    display: none;

    @include tablet {
      display: block;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #cdd6dd;
      height: fit-content;
      margin-top: 18px;
    }
  }

  &__region-buttons {
    &--hide {
      @include tablet {
        display: none;
      }
    }
  }

  &__region-button,
  &__prefecture-button {
    display: none;

    @include tablet {
      display: block;
      padding: 16px;
      width: -webkit-fill-available;
      border-bottom: 1px solid #cdd6dd;
      font-size: 15px;
      position: relative;
      background-color: #F9FEFF;
    }

    &:last-child {
      @include tablet {
        border-bottom: none;
      }
    }

    &::after {
      @include tablet {
        content: url('images/top_search_client_arrow_right.svg');
        position: absolute;
        right: 19px;
      }
    }
  }

  &__major_cities {
    background: rgba(213, 239, 244, 0.8);
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 4px;
    gap: 4px;
    max-width: 160px;
  }

  &__major-city-link {
    font-family: 'Hiragino Kaku Gothic Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    color: #006881;
    padding: 3px;
    display: flex;
    align-items: center;

    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 104, 129, 0.2);
    border-radius: 3px;

    &__icon {
      display: inline-block;
      margin-right: 2px;
    }
  }

  &__major-city-button {
    display: none;

    @include tablet {
      display: block;
      padding: 16px;
      width: -webkit-fill-available;
      border-bottom: 1px solid #cdd6dd;
      font-size: 15px;
      position: relative;
      color: #006881;
    }

    &:last-child {
      @include tablet {
        border-bottom: none;
      }
    }

    &::after {
      @include tablet {
        content: url('images/top_search_client_arrow_right.svg');
        position: absolute;
        right: 19px;
      }
    }

    &__icon {
      @include tablet {
        display: inline-block;
        margin: 0 8px;
      }
    }
  }

  &__prefecture-by-region {
    display: none;

    &--show {
      @include tablet {
        display: block;
      }
    }
  }

  &__back-button {
    display: none;

    @include tablet {
      display: block;
      font-size: 12px;
      color: #1c8a9e;
      margin-top: 22px;
      text-decoration: underline;
    }

    &::before {
      @include tablet {
        content: url('images/top_search_client_arrow_left.svg');
        margin-right: 6px;
      }
    }
  }
}
