.pc-view {
  display: block;

  @include tablet {
    display: none;
  }
}

.tablet-view {
  display: none;

  @include tablet {
    display: block;
  }

  @include smartphone {
    display: none;
  }
}

.sp-view {
  display: none;

  @include smartphone {
    display: block;
  }
}

.pc-and-tablet-view {
  display: block;

  @include smartphone {
    display: none;
  }
}

.tablet-and-sp-view {
  display: none;

  @include tablet {
    display: block;
  }
}

.pc-and-sp-view {
  display: block;

  @include tablet {
    display: none;
  }

  @include smartphone {
    display: block;
  }
}
