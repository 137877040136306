@import "Stylesheets/nurikae/mixin/media_query";

.star-rating {
  display: flex;
  align-items: center;
  gap: 0 10px;
  width: fit-content;

  @include tablet {
    gap: 0 8px;
  }

  &__icon-container {
    display: flex;
    gap: 0 5px;

    @include tablet {
      gap: 0 4px;
    }
  }

  &__icon {
    line-height: 1;
    width: 24px;

    @include tablet {
      width: 16px;
    }

    svg {
      width: 100%;
      height: auto;
      fill: #e0eaef;
    }

    &--fill {
      svg {
        fill: #f8bf52;
      }
    }
  }

  &__number {
    color: #393939;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;

    @include tablet {
      font-size: 22px;
    }
  }
}
