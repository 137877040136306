@import "Stylesheets/nurikae/mixin/media_query";

.header-sp {
  @include tablet {
    height: 48px;
    border-bottom: #e0eaef 1px solid;
    position: relative;
    z-index: 100;
  }

  &__inner {
    @include tablet {
      max-width: 1000px;
      height: 100%;
      margin-inline: auto;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
    }
  }

  &__logo {
    @include tablet {
      width: 100px;
    }
  }
}

.header-sp-hamburger-menu {
  &__open-button {
    @include tablet {
      padding: 0 5px;
      display: block;
    }

    .header-sp--open & {
      @include tablet {
        display: none;
      }
    }
  }

  &__close-button {
    @include tablet {
      color: #1c8a9e;
      font-size: 10px;
      line-height: 1;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px 0;
    }

    .header-sp--open & {
      @include tablet {
        display: flex;
      }
    }
  }
}

.header-sp-navigation {
  @include tablet {
    width: 100%;
    height: calc(100dvh - 48px);
    background-color: #fff;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    display: none;
  }

  &__inner {
    @include tablet {
      height: 100%;
      padding-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  &__contents-cassette {
    @include tablet {
      margin-bottom: 26px;
      flex: 1;
    }

    &:last-of-type {
      @include tablet {
        margin-bottom: 0;
      }
    }
  }

  &__heading {
    @include tablet {
      margin-bottom: 8px;
      padding-left: 16px;
      display: flex;
      align-items: center;
      gap: 0 4px;
    }
  }

  &__heading-text {
    @include tablet {
      color: #333;
      font-size: 13px;
      line-height: 1;
    }
  }

  &__list {
    @include tablet {
      border-top: 1px solid #cdd6dd;
      border-bottom: 1px solid #cdd6dd;
    }

    &--sub {
      @include tablet {
        border-bottom: none;
      }
    }
  }

  &__item {
    @include tablet {
      border-bottom: 1px solid #cdd6dd;
    }

    &:last-of-type {
      @include tablet {
        border-bottom: none;
      }
    }
  }

  &__accordion {
    @include tablet {
      box-shadow: none;
      outline: none;
    }
  }

  &__accordion-button {
    @include tablet {
      color: #333;
      font-size: 15px;
      font-weight: bold;
      padding: 13px 40px 13px 16px;
      display: block;
      background: #fafafa url("./images/plus.svg") no-repeat right 26px center;
      box-shadow: none;
      outline: none;
    }

    &::-webkit-details-marker {
      display: none;
    }

    .header-sp-navigation__accordion[open] & {
      @include tablet {
        background-image: url("./images/minus.svg");
      }
    }
  }

  &__drop-down {
    @include tablet {
      border-bottom: 1px solid #cdd6dd;
      box-shadow: none;
      outline: none;
    }

    &:last-of-type {
      @include tablet {
        border-bottom: none;
      }
    }
  }

  &__drop-down-button {
    @include tablet {
      color: #333;
      font-size: 15px;
      padding: 13px 40px 13px 42px;
      display: block;
      background: #fafafa url("./images/arrow_down.svg") no-repeat right 28px center;
      box-shadow: none;
      outline: none;
    }

    &::-webkit-details-marker {
      display: none;
    }

    .header-sp-navigation__drop-down[open] & {
      @include tablet {
        background-image: url("./images/arrow_up.svg");
      }
    }
  }

  &__link {
    @include tablet {
      color: #333;
      font-size: 15px;
      font-weight: bold;
      padding: 13px 40px 13px 16px;
      display: block;
      background: #fafafa url("./images/arrow_right.svg") no-repeat right 28px center;
    }

    &:hover {
      @include tablet {
        opacity: 1;
      }
    }

    &--sub {
      @include tablet {
        font-weight: normal;
        padding-left: 54px;
      }
    }
  }
  &__sub-link {
    @include tablet {
      color: #006881;
      text-decoration: underline;
    }
  }

  &__sub-section {
    @include tablet {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 34px 0;
    }
  }

  &__description {
    @include tablet {
      color: #98a6b5;
      font-size: 13px;
      line-height: 1.8;
      text-align: center;
    }
  }

  &__nurikaeru {
    @include tablet {
      width: 84px;
    }
  }
}
