@import "Stylesheets/nurikae/mixin/media_query";

.top-about {
  border-radius: 12px;
  background: #d8f0f2;
  padding: 65px 0 48px 83px;
  position: relative;

  @include tablet {
    padding: 30px;
  }

  &__content {
    padding: 65px 0 48px 83px;
  }

  &__text {
    line-height: 1;
    color: #333;

    &--small {
      font-size: 14px;
      margin-bottom: 25px;

      @include tablet {
        font-size: 12px;
        margin-bottom: 17px;
      }
    }

    &--big {
      font-size: 24px;
      font-weight: bold;

      @include tablet {
        font-size: 20px;
      }
    }

    &--blue {
      color: #006881;
      margin-top: 20px;

      @include tablet {
        margin-top: 10px;
      }
    }

    &--inline-block {
      display: inline-block;
    }

    &--pc {
      display: block;

      @include tablet {
        display: none;
      }
    }

    &--sp {
      display: none;

      @include tablet {
        display: block;
        line-height: 32px;
      }
    }
  }

  &__link {
    display: block;
    width: fit-content;
    background: transparent;
    border-radius: 30px;
    border: 1px solid #1c8a9e;
    font-size: 13px;
    font-weight: bold;
    padding: 13px;
    color: #1c8a9e;
    line-height: 1;
    margin-top: 42px;

    &:hover {
      background: #1c8a9e;
      color: #fff;

      svg {
        fill: #fff;
      }
    }

    @include tablet {
      margin-top: 75px;
    }
  }

  &__link-icon {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-left: 10px;
  }

  &__illustration {
    position: absolute;

    &--pc {
      display: block;
      width: 312px;
      right: 84px;
      top: 0;
      bottom: 0;
      margin: auto;

      @include tablet {
        display: none;
      }
    }

    &--sp {
      display: none;
      bottom: 33px;
      right: 32px;
      width: 167px;

      @include tablet {
        display: block;
      }
    }
  }
}
