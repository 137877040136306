@import "Stylesheets/nurikae/mixin/media_query";
@import "Stylesheets/nurikae/util/grid";

.footer {
  color: #192c55;
  padding: 45px 0 24px;
  background-color: #fafafa;

  @include tablet {
    padding: 60px 0 12px;
  }

  &__inner {
    @include set-grid-container;
  }

  &__contents-cassette {
    @include set-grid-column(12, 4);

    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #dbdbdb;

    @include tablet {
      margin-bottom: 16px;
      padding-bottom: 30px;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__site-info {
    display: grid;
    gap: 10px;
    place-content: space-between;

    @include tablet {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }

  &__site-logo {
    width: 190px;

    @include tablet {
      width: 126px;
    }

    img {
      width: 100%;
    }
  }

  &__company-info {
    display: flex;
    align-items: center;
    gap: 0 20px;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px 0;
    }
  }

  &__company-logo {
    width: 98px;
    display: block;

    @include tablet {
      width: 65px;
    }

    img {
      width: 100%;
    }
  }

  &__company-navigation-and-copyright {
    display: flex;
    align-items: center;
    gap: 0 20px;
    flex: 1;

    @include tablet {
      width: 100%;
    }
  }

  &__company-copyright {
    font-size: 10px;
  }
}

.footer-jpx {
  max-width: 211px;
  padding: 10px;
  display: flex;
  gap: 0 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  grid-row: 2 / 3;

  @include tablet {
    grid-row: 3 / 4;
  }

  &__logo {
    width: 52px;
  }

  &__notes {
    color: #808080;
    font-size: 10px;
    flex: 1;
  }
}

.footer-site-navigation {
  font-size: 12px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 22px;
  grid-column: 2 / 3;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 28px;
    grid-row: 2 / 3;
    grid-column: auto;
  }

  &__list {
    &:nth-of-type(4) {
      grid-row: 2 / 3;
      grid-column: 3 / 4;

      @include tablet {
        grid-row: 3 / 4;
        grid-column: auto;
      }
    }
  }

  &__item {
    margin-bottom: 12px;

    @include tablet {
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link,
  &__text {
    color: #192c55;
    font-weight: bold;
  }

  &__text {
    margin-bottom: 10px;
  }

  &__sub-item {
    margin-bottom: 8px;
    padding-left: 1em;
    position: relative;

    @include tablet {
      margin-bottom: 10px;
    }

    &::before {
      content: "-";
      position: absolute;
      inset: 0;
      right: 100%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__sub-link {
    color: #192c55;
  }
}

.footer-company-navigation {
  flex: 1;

  &__list {
    font-size: 10px;
    display: flex;
    gap: 0 3em;
  }

  &__item {
    position: relative;

    &:not(:last-of-type) {
      &::after {
        content: "｜";
        position: absolute;
        inset: 0;
        left: calc(100% + 1em);
      }
    }
  }

  &__link {
    color: #192c55;
  }
}
