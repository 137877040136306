@import "Stylesheets/nurikae/base";

.home-index {
  background-color: #f1f7fb;
  padding-bottom: 120px;

  @include tablet {
    padding-bottom: 90px;
  }

  &__search-client {
    margin-top: 44px;

    @include smartphone {
      margin-top: 32px;
    }
  }

  &__latest-contents {
    margin-top: 94px;

    @include tablet {
      margin-top: 43px;
    }
  }

  &__latest-contents-caption {
    font-size: 22px;
    font-weight: bold;
  }

  &__introduce-reviews-cards,
  &__reform-example-cards {
    margin-top: 28px;
  }

  &__introduce-reviews-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 50px 28px;

    @include tablet {
      grid-template-columns: auto;
      grid-template-rows: repeat(6, 1fr);
      gap: 12px 0;
    }
  }

  &__reform-example-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px 28px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 16px;
    }
  }

  &__about {
    margin-top: 100px;

    @include tablet {
      margin-top: 50px;
    }
  }

  &__columns {
    background-color: #fff;
    margin-top: 106px;
  }

  &__supervisor {
    margin-top: 120px;

    @include tablet {
      margin-top: 61px;
    }
  }
}
