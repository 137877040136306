.survey-modal {
  display: none;

  .header-content {
    text-align: center;
    margin: 15px;
  }

  .paint-bucket {
    position: absolute;
    top: 30px;
    right: 0;
    width: 18%;

    @media (max-width: 768px) {
      top: 15px;
      z-index: -1;
      width: 26%;
    }
  }

  .logo {
    position: absolute;
    width: 140px;
    top: 30px;
    right: 0;
    bottom: 0;
    left: 15px;

    @media (max-width: 768px) {
      width: 90px;
      top: 15px;
    }
  }

  .logo.position_bottom_right {
    right: 20px;
    bottom: 15px;
    top: auto;
    left: auto;

    @media (max-width: 768px) {
      right: 10px;
    }
  }

  .form-checklist {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .checked-item {
    background-color: #28A8C1 !important;
    color: #ffffff;
  }

  .form-checklist li {
    font-weight: bold;
    padding: 8px 9px;
    width: 100%;
    height: 42px;
    background: #FAFAFA;
    border: 2px solid #CDD6DD;
    border-radius: 10px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      height: auto;
    }
  }

  .form-checklist label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 35px;
    font-size: 14px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }

  .form-checklist input[type="checkbox"] {
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    outline: none;
    cursor: pointer;
  }

  .form-checklist input[type="checkbox"]:checked {
    background-color: #28A8C1;
    border-color: #ffffff;
  }

  .form-checklist input[type="checkbox"]:checked::before {
    content: '\2713';
    color: #fff;
    font-size: 16px;
    display: block;
    text-align: center;
    line-height: 20px;
  }

  .form-other {
    margin-top: 10px;
  }

  textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }

  .form-submit {
    margin-top: 20px;
    text-align: center;
  }

  .error-message {
    color: red;
    font-weight: bold;
    font-size: 14px;
    display: none;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .survey-modal__wrap {
    position: relative;
  }

  .survey-modal__close {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    cursor: pointer;
    display: block;
    width: 45px;
    height: 45px;
    background-image: url("../../../packs/images/modal-close.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    position: absolute;
    top: calc(-45px / 2);
    right: calc(-45px / 2);
    z-index: 1;

    @media (max-width: 768px) {
      width: 45px;
      height: 45px;
      right: calc(-30px / 2);
    }
  }

  .survey-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
  }

  .is-form {
    .form-header {
      .for_sp {
        display: none;
      }

      @media (max-width: 768px) {
        .for_pc {
          display: none;
        }
        .for_sp {
          display: block;
        }
      }

      font-weight: bold;
      .header-text-1 {
        font-size: 24px;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
      .header-text-2 {
        font-size: 16px;

        @media (max-width: 768px) {
          font-size: 13px;
        }
      }

      .header-sub-text {
        padding-top: 16px;
        color: #006881;
        font-size: 16px;

        @media (max-width: 768px) {
          padding-top: 8px;
          font-size: 13px;
        }
      }
    }

    .form-submit button {
      background-color: #28A8C1;
      color: #fff;
      border: none;
      border-radius: 8px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      width: 180px;

      @media (max-width: 768px) {
        width: 140px;
      }
    }

    .form-submit button.gray {
      background-color: #CDD6DD;
    }

    .form-submit button.gray:hover {
      background-color: #CDD6DD;
    }

    .form-submit button:hover {
      background-color: #0079b0;
    }

    .survey-modal__container {
      background-color: #EDF9FC;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      max-height: 100vh;
      overflow-y: auto;
      box-sizing: border-box;
      position: relative;
      padding: 40px 120px 20px;

      @media (max-width: 768px) {
        width: 320px;
        padding: 30px 15px 15px 15px;
      }
    }
  }

  .survey-modal__overlay.is-thanks {
    display: none;

    .form-header {
      font-weight: bold;

      .header-text-1 {
        font-size: 24px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      .header-text-2 {
        font-size: 16px;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }

    .form-submit {
      margin-top: 20px;
      text-align: center;
    }

    .form-submit button {
      background-color: #e1981d;
      color: #fff;
      border: none;
      border-radius: 8px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      width: 180px;

      @media (max-width: 768px) {
        width: 140px;
      }
    }

    .form-submit button:hover {
      background-color: #e3900f;
    }

    .survey-modal__container {
      background-color: #FFFBF2;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      max-height: 100vh;
      overflow-y: auto;
      box-sizing: border-box;
      position: relative;
      padding: 40px 120px 20px;

      @media (max-width: 768px) {
        width: 320px;
        padding: 30px 15px 15px 15px;
      }
    }

    .img-orange-check {
      width: 45px;
      margin: 0 auto;
    }

    .img-nuri-kero {
      width: 130px;
      margin: 0 auto;
    }
  }
}
.survey-modal.is-open {
  display: block;
}

.micromodal-slide {
  .survey-modal__container,
  .survey-modal__overlay {
    will-change: transform;
  }

  &[aria-hidden="false"] {
    .survey-modal__overlay {
      animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .survey-modal__container {
      animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden="true"] {
    .survey-modal__container {
      animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .survey-modal__overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
